import React, { useEffect } from 'react';
// import { useRive } from '@rive-app/react-canvas';
import overview from '../../assets/qfc_overview.png';
import overviewMobile from '../../assets/qfc_overview_mobile.png';
import suppliers from '../../assets/qfc_suppliers.png';
import suppliersMobile from '../../assets/qfc_suppliers_mobile.png';
import check from '../../assets/qfc_demo_check.svg';
import ai from '../../assets/ai_icon.svg';
import haccp from '../../assets/qfc_haccp.png';
import haccpMobile from '../../assets/qfc_haccp_mobile.png';
import routine from '../../assets/qfc_rutin.png';
import routineMobile from '../../assets/qfc_rutin_mobile.png';

import FadeInSection from '../../animation/fadein_onscroll';
import './features.css';

const Features = () => {
  useEffect(() => {
    const handleScroll = () => {
      const images = document.querySelectorAll('.parallax img');
      images.forEach(image => {
        const rect = image.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (rect.top <= windowHeight && rect.bottom >= 0) {
          const offset = (windowHeight - rect.top) * 0.15; // Adjust speed here
          const scale = 1 + (windowHeight - rect.top) * 0.00018; // Adjust scaling factor here

          image.style.transform = `translateY(${offset}px) scale(${scale})`;
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

//   const { RiveComponent } = useRive({
//     src: '../../assets/task_done.riv',
//     autoplay: true,
//   });

  return (
    <div>
      <div className="section parallax" tabindex="3">
        <div className="image-container">
          <img src={overview} alt="Översikt" className="feature-image desktop" />
          <img src={overviewMobile} alt="Översikt" className="feature-image mobile" />
        </div>
        <div className="text-container">
          <div className="text-content">
            <h2>Överblick & kontroll av hela din verksamhet</h2>
            <p>Kartlägg alla processer & flöden i din verksamhet och få rätt dokumentation på rätt plats, med tillgång till smarta funktioner som ger dig total kontroll genom varje steg.</p>
          </div>
        </div>
      </div>

      <div className="section reverse parallax"tabindex="3">
        <div className="text-container text-reverse">
          <div className="text-content">

			      <p className="text-content-tag">HACCP</p>
            <h2>Riskhantering & övervakning med AI 
            <img src={ai} alt="AI" className="ai-icon"></img> </h2>
            <p>Riskvärdera hela din verksamhet med hjälp av AI och håll den övervakad med skräddarsydda kontroller. Få en komplett HACCP-plan, faroanalys, farostyrningsplan och mycket mer.</p>
          </div>
        </div>
        <div className="image-container reverse">
          <img src={haccp} alt="HACCP" className="feature-image desktop" />
          <img src={haccpMobile} alt="HACCP" className="feature-image mobile" />
        </div>
      </div>

	    <div className="section parallax"tabindex="3">
        <div className="image-container">
          <img src={routine} alt="Rutiner" className="feature-image desktop" />
          <img src={routineMobile} alt="Rutiner" className="feature-image mobile" />
        </div>
        <div className="text-container">
          <div className="text-content">
          <h2>Alla rutiner på plats</h2>
            <p>Få alla nödvändiga rutiner för affär, stöd och livsmedelshantering på plats, med funktioner för komplett dokumentstyrning - justera enkelt efter just din verksamhets behov.</p>
          </div>
        </div>
      </div>

      <div className="section reverse parallax"tabindex="3">
        <div className="text-container text-reverse">
          <div className="text-content">
            <h2>Leverantörs- & certifikatshantering</h2>
            <p>Samla alla leverantörer och deras information på ett ställe. Håll certifikat uppdaterade med automatiska påminnelser.</p>
			
          </div>
        </div>
        <div className="image-container reverse">
          <img src={suppliers} alt="Leverantörer" className="feature-image desktop" />
          <img src={suppliersMobile} alt="Leverantörer" className="feature-image mobile" />
        </div>
      </div>
	
	  <FadeInSection>
		<div className="Section small centered" tabindex="3">
			<div className="text-container centered check">
        <div className="text-content centered">
          <h2>Redo för inspektion</h2>
          <p>All nödvändig historik & dokumentation samlat på ett och samma ställe – tryggt & smidigt, inför revision eller myndighetskontroll!</p>
        </div>
				<img src={check} alt="Bock" className="feature-image check" />
			</div>
		</div>
	  </FadeInSection>

    </div>
  );
};

export default Features;
