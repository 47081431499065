import React from 'react';
import arrowDown from '../assets/arrow_down.svg';
import devices from '../assets/devices.png';
import './home.css';
import FadeInSection from '../animation/fadein_onscroll';
import LandingSection from '../components/columns/columns';
import Features from '../components/features/features';
import Menu from '../components/menu/menu';
import Plans from '../components/plans/plans';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function App() {
  return (
    <div className="App" id='start'>
      <Menu tabindex="0"/>
      <header className="app-header">
        
        <div className="left-half">
          <FadeInSection>
            <div className="app-header-content">
              <h1 className="max-width-text">
                Matsäkerhet utan krångel
              </h1>
              <p className="max-width-text">
                En helhetslösning för livsmedelssäkerhet och kvalitetssäkring enligt HACCP och GFSI standard - skräddarsydd för era behov.
              </p>

              <div className="app-header-buttons">
                <a href='#plans' className="button-primary large margin-right" tabindex="2">Få tillgång nu</a>
                <a href='#about' className="button-secondary large margin-right" tabindex="2">Utforska mer</a>
              </div>
            </div>

        </FadeInSection>
        </div>
        <a href="#about" className="see-more" tabindex="2">
          <img src={arrowDown} alt="symbol" />
        </a>
        <div className="right-half">
        <FadeInSection>
          <img src={devices} alt="symbol" />
        </FadeInSection>
        </div>
      </header>

      <FadeInSection>
        <section id="about" className="Section small center" tabindex="2">
          <LandingSection />
        </section>
      </FadeInSection>

      <FadeInSection>
        <section id="features" className="">
          <Features />
        </section>
      </FadeInSection>

      <FadeInSection>
        <section id="plans" className="">
          <Plans />
        </section>
      </FadeInSection>
      
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('root'));

export default App;
