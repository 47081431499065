import React from 'react';
import facebook from '../../assets/social_facebook.svg';
import linkedin from '../../assets/social_linkedin.svg';
import footerIcon from '../../assets/qfc_symbol_dark.svg';
import appstore from '../../assets/appstore_sv.svg';
import playstore from '../../assets/playstore_sv.svg';
import './footer.css';
import FadeInSection from '../../animation/fadein_onscroll';


function Footer() {

return (
<FadeInSection>
    <footer className="footer row center main-padding">
        <div className="footer-items column" id="contact">
            <div className="column">
                <h2>Bli kvalitetssäkrad du med!</h2>
                <a className="button-primary large margin-right" href="/contact">Kontakta oss</a>
            </div>
            <br />
            <br />
            <div className="column">
            <div className="column">
                <a tabindex="5" className="arrow" href='/terms' aria-label="Läs våra användarvillkor">Användarvillkor</a>
                <a tabindex="5" className="arrow" href='/privacy' aria-label="Läs vår integritetspolicy om hur vi hanterar dina personuppgifter">Integritespolicy</a>
            </div>
            <div className="row store">
                <a tabindex="5" className="store-icon" href='https://apps.apple.com/se/app/quality-food-control/id6469708374?l=en-GB' target="blank" aria-label="Ladda ner på app store">
                    <img src={appstore} className="" alt="ladda ner på app store" />
                </a>
                <a tabindex="5" className="store-icon" href='https://play.google.com/store/apps/details?id=com.qualityfoodcontrol.app&hl=sv' target="blank" aria-label="Ladda ner på play store">
                    <img src={playstore} className="" alt="ladda ner på play store" />
                </a>
            </div>
            <div className="row">
                <a tabindex="5" className="social-icon" href='https://www.facebook.com/qualityfoodcontrol' target="blank" aria-label="Följ oss på Facebook">
                    <img src={facebook} className="" alt="följ oss på facebook" />
                    <span className="tooltip">Facebook</span>
                </a>
                <a tabindex="5" className="social-icon" href='https://www.linkedin.com/company/quality-food-control/' target="blank" aria-label="Följ oss på LinkedIn">
                    <img src={linkedin} className="" alt="följ oss på linkedin" />
                    <span className="tooltip">LinkedIn</span>
                </a>
            </div>
            <span className="slogan desktop">
                © 2024 TQM Sweden AB
            </span>
            </div>
        </div>
        <img src={footerIcon} className="symbol" alt="symbol" />
        <br />
        <span className="slogan mobile">
            © 2024 TQM Sweden AB
        </span>
    </footer>
</FadeInSection>
);
}

export default Footer;