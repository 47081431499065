import React, { useEffect } from 'react';
import './home.css';
import './contact.css';
import FadeInSection from '../animation/fadein_onscroll';


function Contact() {
  useEffect(() => {
    const anchors = document.querySelectorAll('a[data-scroll]');

    const handleAnchorClick = (e) => {
      e.preventDefault();
      const target = document.querySelector(e.currentTarget.getAttribute('href'));
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };
  
    anchors.forEach(anchor => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    return () => {
      anchors.forEach(anchor => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.type = 'text/javascript';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '145511650',
          formId: 'd6c552cf-8e5b-44f5-b51a-1c4322e0abc6',
          target: '#hubspotForm',
        });
      }
    };

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="subpage-padding" id="start">
      <FadeInSection>
        <h1>Kontakt</h1>
      </FadeInSection>
      <div className="max-width">
        <FadeInSection>
          <p>
          Vad kul att du vill veta mer om Quality Food Control. Vänligen fyll i formuläret nedan så återkommer vi med mer information eller förslag på nästa steg. Vi ser fram emot att prata med dig!
          </p>
        </FadeInSection>
      </div>
      <div className="hubspotForm" id="hubspotForm" style={{ marginTop: '2rem' }}></div>
    </div>
  );
}

export default Contact;