import React, { useEffect } from 'react';
import './home.css';
import './demo.css';
import FadeInSection from '../animation/fadein_onscroll';


function Demo() {
  useEffect(() => {
    // Scroll behavior for anchors
    const anchors = document.querySelectorAll('a[data-scroll]');
    
    // Function to handle click event
    const handleAnchorClick = (e) => {
      e.preventDefault();
      const target = document.querySelector(e.currentTarget.getAttribute('href'));
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };
    
    // Add event listeners
    anchors.forEach(anchor => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    // Cleanup function to remove event listeners
    return () => {
      anchors.forEach(anchor => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);

  useEffect(() => {
    // Load external HubSpot script
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.type = 'text/javascript';
    script.async = true;

    document.body.appendChild(script);

    // Initialize HubSpot form after script loads
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '145511650',
          formId: 'd6c552cf-8e5b-44f5-b51a-1c4322e0abc6',
          target: '#hubspotForm',
        });
      }
    };

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="subpage-padding" id="start">
      <FadeInSection>
        <h1>Boka en gratis demo</h1>
      </FadeInSection>
      <div className="max-width">
        <FadeInSection>
          <p>
          Vänligen fyll din kontaktinfomration nedan så återkommer vi snarast med mer information och förslag på datum för en demo. Vi ser fram emot att visa hur vår plattform kan förenkla och effektivisera ert arbete!
          </p>
        </FadeInSection>
      </div>
      <div className="hubspotForm" id="hubspotForm" style={{ marginTop: '2rem' }}></div>
    </div>
  );
}

export default Demo;