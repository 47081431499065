import React, { useState } from 'react';
import checkmarkIcon from '../../assets/checkmark.svg';
import aiIcon from '../../assets/ai_icon.svg';
import './plans.css';

const Plans = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  const togglePlan = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <div className="comparison-table plans-padding" id="plans" tabindex="4">
      <div className="comparison-title">
        <h2>Priser</h2>
        <p>Du får en allt-i-ett lösning för verksamhetsstyrning - med översikt av hela din verksamhet, HACCP plan, övervakning, egenkontroller, rapporter och mycket mer - helt utan bindningstid!</p>
      </div>
      
      <div className="toggle-container">
        <button className={`toggle-button ${isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Årsvis
          <div className={`discount ${!isAnnual ? '' : 'active'}`}>
            <span>Spara 10%</span>
          </div>
        </button>
        <button className={`toggle-button ${!isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Månadsvis
        </button>
      </div>

      <div className="plans">
      <div className="plan" tabindex="4">
          <div>
            <h3>Baspaket</h3>
            <p className="sub-label plan-description">För verksamheter som vill säkerställa att de följer lagar & regler.</p>
            <div className="flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">545 kr</h4>
                  <h4 className="inline-block">495 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">545 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              )}
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
              <li><img src={aiIcon} alt="Checkmark Icon" className="checkmark-icon" />AI tränad för <span className='bold'>basnivå</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>5 st</span> användare</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />1 Affärsenhet</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Filhantering med <span className='bold'>500 GB</span> lagring</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Bas</span> support</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Ingen bindningstid</li>
              </ul>
            </div>
            <div className="divider"></div>
            <div className="plan-section">
              <p className="sub-label tiny">Tillägg</p>
              <ul className="plan-features">
                <li>1 till Affärsenhet<span className="price-small">• 295 kr/mån</span></li>
                <li>1 TB mer lagring<span className="price-small">• 199 kr/mån</span></li>
                <li>HR-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Arbetsmiljö-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Skadedjurs-paket<span className="price-small">*</span></li>
                <li>KRAV<span className="price-small">• 99 kr/mån</span></li>
                <li>EKO<span className="price-small">• 99 kr/mån</span></li>
                <li>MSC / ASC<span className="price-small">• 99 kr/mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
               <p className="sub-label tiny">Konsultation</p>
               <ul className="addons-features">
                 <li>Få stöd av våra livsmedelsexperter med över 100 års kombinerad erfarenhet<span className="price-small">*</span></li>
                 <br></br>
                 <p className="sub-label explainer">*Kontakta oss för offert</p>
               </ul>
             </div>
          </div>
          <br></br>
          <div className="plan-button">
            <a tabindex="4" href="/contact" className="button-secondary">Kontakta oss</a>
          </div>
        </div>
        <div className="plan" tabindex="4">
          <div>
            <h3 className="highlighted">IP-standard</h3>
            <p className="sub-label plan-description">För verksamheter med IP-certifiering, med tillgång till alla funktioner.</p>
            <div className="flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">1105 kr</h4>
                  <h4 className="inline-block">995 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">1105 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              )}
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
              <li><img src={aiIcon} alt="Checkmark Icon" className="checkmark-icon" />AI tränad för <span className='bold'>IP</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Obegränsat</span> antal användare</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />1 Affärsenhet</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Filhantering med <span className='bold'>1 TB</span> lagring</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Premium</span> support</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Ingen bindningstid</li>
              </ul>
            </div>
            <div className="divider"></div>
            <div className="plan-section">
              <p className="sub-label tiny">Tillägg</p>
              <ul className="plan-features">
              <ul className="addons-features">
                <li>1 till Affärsenhet<span className="price-small">• 495 kr/mån</span></li>
                <li>1 TB mer lagring<span className="price-small">• 199 kr/mån</span></li>
                <li>HR-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Arbetsmiljö-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Skadedjurs-paket<span className="price-small">*</span></li>
                <li>KRAV<span className="price-small">• 99 kr/mån</span></li>
                <li>EKO<span className="price-small">• 99 kr/mån</span></li>
                <li>MSC / ASC<span className="price-small">• 99 kr/mån</span></li>
              </ul>
              </ul>
            </div>
            <div className="plan-section">
               <p className="sub-label tiny">Konsultation</p>
               <ul className="addons-features">
                 <li>Få stöd av våra livsmedelsexperter med över 100 års kombinerad erfarenhet<span className="price-small">*</span></li>
                 <br></br>
                 <p className="sub-label explainer">*Kontakta oss för offert</p>
               </ul>
             </div>
          </div>
          <br></br>
          <div className="plan-button">
            <a tabindex="4" href="/contact" className="button-primary">Kontakta oss</a>
          </div>
        </div>
        <div className="plan" tabindex="4">
          <div>
            <h3>GFSI-standard</h3>
            <p className="sub-label plan-description">För företag med högre certifieringar som BRC, FSSC m.fl.</p>
            <div className="flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">1 425 kr</h4>
                  <h4 className="inline-block">1 295 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">1 425 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              )}
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
                <li><img src={aiIcon} alt="Checkmark Icon" className="checkmark-icon" />AI tränad för <span className='bold'>GFSI</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Obegränsat</span> antal användare</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />1 Affärsenhet</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Filhantering med <span className='bold'>1,5 TB</span> lagring</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Premium</span> support</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Ingen bindningstid</li>
              </ul>
            </div>
            <div className="divider"></div>
            <div className="plan-section">
              <p className="sub-label tiny">Tillägg</p>
              <ul className="plan-features">
              <ul className="addons-features">
                <li>1 till Affärsenhet<span className="price-small">• 695 kr/mån</span></li>
                <li>1 TB mer lagring<span className="price-small">• 199 kr/mån</span></li>
                <li>HR-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Arbetsmiljö-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Skadedjurs-paket<span className="price-small">*</span></li>
                <li>KRAV<span className="price-small">• 99 kr/mån</span></li>
                <li>EKO<span className="price-small">• 99 kr/mån</span></li>
                <li>MSC / ASC<span className="price-small">• 99 kr/mån</span></li>
              </ul>
              </ul>
            </div>
            <div className="plan-section">
               <p className="sub-label tiny">Konsultation</p>
               <ul className="addons-features">
                 <li>Få stöd av våra livsmedelsexperter med över 100 års kombinerad erfarenhet<span className="price-small">*</span></li>
                 <br></br>
                 <p className="sub-label explainer">*Kontakta oss för offert</p>
               </ul>
             </div>
          </div>
          <br></br>
          <div className="plan-button">
          <a tabindex="4" href="/contact" className="button-secondary">Kontakta oss</a>
          </div>
        </div>
        <div className="plan"  tabindex="4">
          <div>
            <h3>Enterprise</h3>
            <p className="sub-label plan-description">För mer komplexa företag med behov av skräddarsydda lösningar.</p>
            <div>
              <h4 className="inline-block">Skräddarsytt pris</h4>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skräddarsytt upplägg</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Exklusiv</span> support</li>
              </ul>
            </div>
          </div>
          <div className="plan-button">
            <a tabindex="4" href="/contact" className="button-secondary">Kontakta oss</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
